import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import IntroHostingPlansParams from "../components/HostingPlans/IntroHostingPlansParams"
import CustomerSupportParams from "../components/Index/CustomerSupportParams"
import PromoInfoRowParams from "../components/PromoInfoRow/PromoInfoRowParams"
import SiteBenefitsParams from "../components/SiteBenefits/SiteBenefitsParams"
import FreeHosting from "../components/Common/FreeHosting";
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";
import hostingImg from "../assets/images/hosting-discount.png";


const PrestashopSsdHosting = () => {
    return (
        <Layout>
            <SEO title="PrestaShop Hosting - Sitebunker - Web Hosting for online shops"
                 description="Are you looking for efficient PrestaShop hosting for your online shop? Search no more! Sitebunker is here to support you with any budget subscriptions."
            />
            <Navbar/>
            <IntroHostingBgWhite
                title={'Premium resources for PrestaShop Web Hosting'}
                subtitle={'Choose from the packages below and enjoy the security of your online shop and a premium module of your choice.'}
            />


            <HostingPlanBlock category="Prestashop EN"
                              gift='Premium Prestashop Module'
                              discount='30'
                              showPromos={true}/>
            <BannerArea
                pageTitle="High Performance PrestaShop Hosting"
                pageSubtitle="LiteSpeed Cache Server included. Efficient upload speed"
                pageDescription="Scale up your online business with an efficient PrestaShop hosting subscription. Enjoy premium resources and focus on sales."
                svgIllustration="prestashop"
            />
            <FreeHosting image={hostingImg}
                         title='Choose the right annually hosting package for you and get a Premium Prestashop Module'
                         text1='Scale-up your business with efficient Prestashop hosting for a year and get a free Prestashop Premium Module'
                         text2='Enjoy the offer and grow your business. Regarding the offer, it applies to all of the above packages, with the exception of the Start package.'/>
            <PromoInfoRowParams
                title1="Reliable PrestaShop Hosting"
                paragraph1="99.99% UpTime, fast upload speed provided by LiteSpeed web servers and increased security against DDoS attacks assured by the newly implemented Arbor solution, and the anti-hacking protection from Imunify360, which are all included in the SiteBunker hosting subscriptions."
                title3="FREE Migration"
                paragraph3="The SiteBunker Customer Support Team is here to assist you with FREE migration when you take the decision to change your current  web hosting provider. Just prepare the necessary details for the migration and we’ll take care of everything without any hassle."
                title2="Efficient Web Hosting"
                paragraph2="Without a suitable PrestaShop hosting, your online business can suffer. Why take the risk when you have a team of web hosting professionals at your service? Ask now for a customized solution, no matter the size of the project you are working on."/>
            <InfoBlocks/>
            <ClientFeedback
                title="Curious why our clients choose"
                bold="PrestaShop Hosting?"/>
            <SiteBenefitsParams
                mainTitle="AFFORDABLE SUBSCRIPTION"
                title1="AFFORDABLE SUBSCRIPTION"
                title2="99.99% UPTIME"
                title3="SUPORT 24/7"
                text1="At SiteBunker you can choose any PrestaShop hosting package or ask for a tailor made subscription, designed according to your requirements. Increase your online shop performance with an efficient web hosting."
                text2="At SiteBunker we understand how important it is to rely on an uninterrupted online presence when you need more time to focus on your sales objectives. Choose SiteBunker for a stable web hosting. Let us be your trustful provider."
                text3="You are constantly connected with the SiteBunker Customer Support Team. You can open an online ticket at any time and we’ll reply in a short time with immediate solutions, regardless of the technical challenge you face."
            />
            <CustomerSupportParams
                title="Tailored made hosting solutions."
                paragraph1="At SiteBunker, we take your PrestaShop hosting needs seriously and we provide the best customized hosting subscription, designed according to your preferences."
                paragraph2="We quickly analyze the technical requirements and reply with the best option for you."
                buttonText="Contact us here."
            />
            <Footer/>
        </Layout>
    )
}

export default PrestashopSsdHosting
